<template>
  <div>
    <Environment v-if="environment != 'production'"/>
    <cmsHeader/>
    <Toast />

  <div class="container">

<div class="formcontainer form-horizontal" v-if="blog">
  <h1>Blog : Edit </h1>
  <p>Select <b>Active</b> to show your post on the <b>Blog</b> page or select <b>Pending</b> to temporarily hide the post.
  Enter the information below and click the <b>Submit</b> button to submit your post.</p>

    <input type="hidden" name="id" value="<?=$id?>" />
    
    <div class="form-group form-group-sm">
      <label class="col-xs-12 col-sm-2 control-label" for="status">Status <span class="required">*</span></label>
        <div class="form-container-input">
          <RadioButton id="status1" name="status" v-model="blog.status" value="active" /> <label for="status1" class="radio-inline statusactive">Active</label>
          <RadioButton id="status2" name="status" v-model="blog.status" value="pending" /> <label for="status2" class="radio-inline statuspending">Pending</label>
        </div>
    </div>
    
    <div class="form-group form-group-sm">
        <label class="col-xs-12 col-sm-2 control-label" for="title"> Title <span class="required">*</span> : </label>
      <div class="col-xs-12 col-sm-8">
        <InputText name="Title" class="formwidthmedium form-control" v-model="blog.title" size="60" />
        </div>
    </div>
      
    <div class="form-group form-group-sm">
      <label class="col-xs-12 col-sm-2 control-label" for="date">Date <span class="required">*</span></label>
        
        <div class="col-sm-2">
          <Calendar
            name="Date"
            v-model="blog.date"
            :showIcon="true"
          />
      </div>
    </div>
    <div class="form-group form-group-sm">
        <label class="col-xs-12 col-sm-2 control-label" for="post">Post <span class="required">*</span>
          <div class="tooltipposition btn-group-vertical">
            <button type="button" class="btn-default btn-sm tooltip-btn new-tooltip" data-toggle="tooltip" data-placement="bottom" title="Use the editor to format text (bold, headings, lists, etc.) To add a link to a website or email address, click the Link button on the header and select the appropriate. Do not use this textbox to post youtube videos. Use the Video field below and write a short paragraph about the video here!"><span class="glyphicon glyphicon-info-sign" aria-hidden="true"></span></button>
          </div>
        </label>

        <div class="col-xs-12 col-sm-10">
          <Editor
            v-model="blog.post"
            editorStyle="height: 320px"
          />
        </div>
    </div>
    <div class="form-group form-group-sm">
      <label class="col-xs-12 col-sm-2 control-label" for="formGroupInputSmall">Photos</label>
      <div class="required">&nbsp;&nbsp;</div>
      <div class="col-xs-12 col-sm-10">
        <FileUpload
          name="files[]"
          :url="`${baseURL}/api/v1/upload/blog/images`"
          :multiple="true"
          @error="uploadError"
          @upload="uploadComplete"
        />
      </div>
    </div>
  
    <div class="form-group form-group-sm" v-show="images.length >= 1">
      <label class="col-xs-12 col-sm-2 control-label" for="formGroupInputSmall">Uploaded Photos</label>
      <div class="required">&nbsp;&nbsp;</div>
      <div class="section-blog-image">
        <div v-for="(image, index) in images" :key="index">
          <img class="blog-image" :src="baseURL + blogImagePath + image.filename" />
          <div class="blog-image-menu"><span @click="deleteImageFromFileSystem(image)"><i class="pi pi-trash blog-image-menu-trash"/></span></div>
        </div>
      </div>
    </div>

    <div class="form-group form-group-sm">
        <label class="col-xs-12 col-sm-2 control-label" for="ytLink">Video 
          <div class="tooltipposition btn-group-vertical">
              <button type="button" class="btn-default btn-sm tooltip-btn new-tooltip" data-toggle="tooltip" data-placement="bottom" title="Only the code is required."><span class="glyphicon glyphicon-info-sign" aria-hidden="true"></span></button>
          </div>
      </label>
      <div class="col-xs-12 col-sm-8">
        <InputText class="formwidthmedium form-control" name="ytLink" v-model="blog.ytLink" maxlength="200" />
      </div>
    </div>

    <div class="form-group form-group-sm">
        <label class="col-xs-12 col-sm-2 control-label" for="scEmbed">Podcast Embed 
          <div class="tooltipposition btn-group-vertical">
              <button type="button" class="btn-default btn-sm tooltip-btn new-tooltip" data-toggle="tooltip" data-placement="bottom" title="Copy and paste a soundcloud embed code into this field. To find this go to the podcast soundcloud homepage. Click SHARE under the episode you would like to share. Click EMBED at the top of the popup. Copy the code provided in the textbox."><span class="glyphicon glyphicon-info-sign" aria-hidden="true"></span></button>
          </div>
      </label>
      <div class="col-xs-12 col-sm-8">
        <Textarea class="formwidthmedium form-control" name="scEmbed" v-model="blog.scEmbed" maxlength="2000" :autoResize="true" />
      </div>
    </div>

    <div class="form-group">
      <label>&nbsp;</label>
      <div class="formwidthmedium"><button type="submit" class="btn-orange btn btn-default" @click="updateResource(blog)">Submit</button></div>
    </div>
</div>

</div>

    <cmsFooter/>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRoute }   from 'vue-router'

// Components
import cmsHeader      from '../../../components/cms/cmsHeader.vue'
import cmsFooter      from '../../../components/cms/cmsFooter.vue'

// API services
import CmsServices    from '../../../services/CmsServices.js'

// Primevue
import { useToast }   from 'primevue/usetoast'
import InputText      from 'primevue/inputtext'
import Calendar       from 'primevue/calendar'
import Editor         from 'primevue/editor'
import Textarea       from 'primevue/textarea'
import RadioButton    from 'primevue/radiobutton'
import FileUpload     from 'primevue/fileupload'

export default {
  name: 'cmsBlogEdit',
  components: {
    cmsHeader, 
    cmsFooter, 
    InputText,
    Calendar,
    Editor,
    Textarea,
    RadioButton,
    FileUpload,
  },
  setup() {
    const toast = useToast()
    const route = useRoute()
    const environment = process.env.VUE_APP_ENVIRONMENT
    const baseURL = process.env.VUE_APP_API_SERVER
    const blogImagePath = process.env.VUE_APP_BLOG_IMAGES
    var blog = ref()
    var images = ref([])
    
    onMounted(() => {
      CmsServices.getResource(route.query.rID)
      .then((response) => {
        if(response.status != 200) {
          toast.add({severity: 'info', summary: 'Information', detail: response.data, life: 5000})
        } else {
          blog.value = response.data
        }
      })
      .catch((error) => {
        toast.add({severity: 'error', summary: error.response.statusText, detail: 'Could not retrieve the post details.', life: 5000})
      })

      CmsServices.getBlogImages(route.query.rID)
      .then((response) => {
        if(response.status != 200) {
          toast.add({severity: 'info', summary: 'Information', detail: response.data, life: 5000})
        } else {
          images.value = response.data
        }
      })
      .catch(() => {
        toast.add({severity: 'error', summary: 'Error', detail: 'Failed to get images!', life: 5000})
      })
    })

    const removeImageFromDatabaseTable = (image) => {
    CmsServices.removeBlogImage(image)
  }

  const addImagesToDatabaseTable = (images) => {
    CmsServices.addBlogImages({images: images})
  }

    const deleteImageFromFileSystem = (image) => {
    CmsServices.deleteImageFromFileSystem(image)
    .then((response) => {
      if(response.status != 200) {
        toast.add({severity: 'info', summary: 'Information', detail: response.data, life: 5000})
      } else {
        toast.add({severity: 'success', summary: 'Success', detail: "Image deleted!", life: 5000})
        images.value.splice(images.value.indexOf(image),1)
        removeImageFromDatabaseTable(image)
      }
    })
    .catch((error) => {
      toast.add({severity: 'error', summary: 'Delete Image Error', detail: error.message, life: 5000})
    })
  }

  const uploadComplete = (event) => {
    const result = JSON.parse(event.xhr.response)
    result.filename.forEach(filename => {
      const image = {id: null,filename: filename,caption: null,blog_id: Number(route.query.rID),position: images.value.length+1}
      images.value.push(image)
      addImagesToDatabaseTable([image])
    });
    toast.add({severity: 'success', summary: 'Success', detail: result.msg, life: 5000})
  }

  const uploadError = () => {
    toast.add({severity: 'error', summary: 'Upload Error', detail: 'Failed to upload!', life: 5000})
  }

    const updateResource = (blog) => {
      blog.date = new Date(blog.date).toISOString().slice(0,-5)+"Z"
    CmsServices.updateResource(blog)
    .then((response) => {
      if(response.status != 200) {
        toast.add({severity: 'info', summary: 'Information', detail: response.message, life: 5000})
      } else {
        toast.add({severity: 'success', summary: 'Success', detail: "Update complete!", life: 5000})
      }
    })
    .catch((error) => {
      toast.add({severity: 'error', summary: error.response.statusText, detail: error.response.data.reason, life: 5000})
    })
  }

    return {
      environment,
      baseURL,
      blog,
      blogImagePath,
      images,
      updateResource,
      deleteImageFromFileSystem,
      uploadError,
      uploadComplete,
    }
  },
}
</script>

<style lang="scss" scoped>
.form-container-input {
  display: flex;
  width: 80%;
}
.section-blog-image {
  display: grid;
  grid: auto-flow / repeat(6,1fr);
  gap: 1rem;
  padding-left: 1.5rem;
}
.blog-image {
  height: 150px;
  width: 150px;
  border-radius: 2rem;
  border: 1px solid lightgray;
}
.blog-image-menu {
  display: flex;
  flex-direction: row nowrap;
  justify-content: space-around;
  padding-left: 5px;
  padding-right: 5px;
}
.blog-image-menu-trash {
  font-size: 2rem;
  color: red; 
  cursor: pointer;
  margin-top: 5px;
}
</style>